<template>
  <section>
    <div class="content-header">
      <h2>Books</h2>
    </div>
    <div class="content-wrapper">
      <ul>
        <li class="pb-2"><em>Designing Your Life</em> by Bill Burnett and Dave Evans</li>
        <li class="pb-2"><em>Designing Your Work Life: How to Thrive and Change and Find Happiness at Work</em> by Bill Burnett and Dave Evans</li>
        <li class="pb-2"><em>Designing Your New Work Life: How to Thrive and Change and Find Happiness—and a New Freedom—at Work</em> by Bill Burnett and Dave Evans</li>
        <li class="pb-2"><em>Fearless at Work: Timeless Teachings for Awakening Confidence, Resilience, and Creativity in the F Ace of Life's Demands</em> by Michael Carroll</li>
        <li class="pb-2"><em>Grit: The Power of Passion and Perseverance</em> by Angela Duckworth</li>
        <li class="pb-2"><em>Mindset: The New Psychology of Success</em> by Carol Dweck</li>
        <li class="pb-2"><em>Resilient: How to Grow an Unshakable Core of Calm, Strength, and Happiness</em> by Forrest Hanson and Rick Hanson</li>
        <li class="pb-2"><em>Harvard Business Review’s 10 Must Reads on Career Resilience</em> </li>
        <li class="pb-2"><em>Resilience at Work: Practical Tools for Career Success</em> by Kathryn Jackson</li>
        <li class="pb-2"><em>Luck is No Accident: Making the Most of Happenstance in Your Life and Career</em> by Al S. Levin and John D. Krumboltz</li>
        <li class="pb-2"><em>Burnout: The Secret to Unlocking the Stress Cycle</em> by Amelia Nagoski and Emily Nagoski</li>
        <li class="pb-2"><em>Strengthening Mental Health Through Effective Career Development</em> by David Eric Dean Redekopp and Michael Huston</li>
        <li class="pb-2"><em>The Resilience Factor: 7 Keys to Finding Your Inner Strength and Overcoming Life's Hurdles</em> by Andrew Shatté and Karen Reivich</li>
        <li class="pb-2"><em>Change Your World: The Science of Resilience and the True Path to Success</em> by Michael Ungar</li>
      </ul>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '06',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
